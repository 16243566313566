// src/components/MeetTheTeam.js

import React from 'react';


const teamMembers = [
    {
      id: 1,
      name: 'Kush Patel',
      role: 'Founder & Developer',
      picture: require('../assets/kush_profile_picture.jpg'),
    }
    // Add more team members as needed
  ];
  

  const MeetTheTeam = () => (
    <div className="py-8 bg-gray-100 rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-white">Meet the Team</h2>
      <div className={`grid ${teamMembers.length === 1 ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'} gap-8 justify-items-center`}>
        {teamMembers.map(member => (
          <div key={member.id} className="text-center p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
            <img src={member.picture} alt={member.name} className="w-32 h-32 rounded-full mx-auto mb-4 border-4 border-gray-200" />
            <h3 className="font-semibold text-lg">{member.name}</h3>
            <p className="text-sm text-gray-600 ">{member.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
  
export default MeetTheTeam;