// src/components/StartupJourney.js
import React from 'react';
import { motion } from 'framer-motion';
import Timeline from './Timeline';

const StartupJourney = () => (
  <section id="startup-journey" className="py-20 bg-white dark:bg-gray-800">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-white">Our Startup Journey</h2>
      <Timeline />
    </div>
  </section>
);

export default StartupJourney;
