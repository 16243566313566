// src/components/Footer.js

import React from 'react';
import logo from '../assets/logo.png';

const Footer = () => (
  <footer className="bg-gray-800 dark:bg-gray-900 text-white">
    <div className="container mx-auto py-12 px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
      
      {/* Logo and Description */}
      <div>
        <div className="flex items-center mb-4">
          <img src={logo} alt="Cybersecurity AI" className="h-10 w-10 mr-3" />
          <span className="text-2xl font-bold dark:text-white">Cybersecurity AI</span>
        </div>
        <p className="text-gray-400 dark:text-white">
          Providing advanced AI-driven cybersecurity solutions to protect your digital assets and ensure your peace of mind.
        </p>
      </div>
      
      {/* Quick Links */}
      <div>
        <h3 className="text-xl font-semibold mb-4 dark:text-white">Quick Links</h3>
        <ul className="space-y-2">
          <li>
            <a href="#hero" className="hover:text-primary transition dark:text-white">Home</a>
          </li>
          <li>
            <a href="#features" className="hover:text-primary transition dark:text-white">Features</a>
          </li>
          <li>
            <a href="#startup-journey" className="hover:text-primary transition dark:text-white">Timeline</a>
          </li>
          <li>
            <a href="#contact" className="hover:text-primary transition dark:text-white">Contact Us</a>
          </li>
        </ul>
      </div>
      
    </div>
    <div className="border-t border-gray-700 mt-8">
      <p className="text-center text-sm text-gray-400 dark:text-white py-4">&copy; {new Date().getFullYear()} Cybersecurity AI. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
