// src/pages/Home.js

import React from 'react';
import Features from '../components/Features';
import Hero from '../components/Hero';
import StartupJourney from '../components/StartupJourney';
import ContactForm from '../components/ContactForm';
import MeetTheTeam from '../components/MeetTheTeam';

const Home = () => (
  <main>
    <Hero />
    <Features />
    <StartupJourney />
    <MeetTheTeam />
    <section id="contact" className="py-16 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-white">Get in Touch</h2>
        <ContactForm />
      </div>
    </section>
  </main>
);

export default Home;
