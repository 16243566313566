// src/components/Hero.js
import React from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import heroVideo from '../assets/videos/cybersecurity-hero.mp4'; // Ensure the path is correct

const Hero = () => {
  return (
    <section
      id="hero"
      className="relative bg-black text-white h-screen flex items-center justify-center"
    >
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={heroVideo}
        autoPlay
        muted
        loop
        playsInline
        aria-label="Cybersecurity AI demonstration video"
      ></video>

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center px-4">
        <motion.h1
          className="text-5xl md:text-7xl font-bold mb-4 text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Protect Your Digital Future
        </motion.h1>
        <motion.p
          className="text-xl md:text-2xl mb-8 max-w-2xl text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          Leveraging AI to provide cutting-edge cybersecurity solutions tailored to your needs.
        </motion.p>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <ScrollLink
            to="contact"
            smooth={true}
            duration={500}
            offset={-70}
            className="px-6 py-3 bg-primary text-white rounded-full font-semibold hover:bg-blue-700 transition transform hover:scale-105 cursor-pointer"
          >
            RSVP
          </ScrollLink>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
