// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
// Import other reducers as needed

const store = configureStore({
  reducer: {
    user: userReducer,
    // Add other reducers here
  },
});

export default store;
