// src/data/timelineData.js

export const milestones = [
    {
      date: '2024-10',
      title: 'Development Started',
      description:
        'Fine-tuning stage',
      icon: '🏆',
    },
    {
      date: '2024-11',
      title: 'Pending',
      description:
        'Stay-tuned.',
      icon: '🔍',
    },
  ];
  