// src/components/Features.js
import React from 'react';
import { FaLock, FaRobot, FaShieldVirus, FaChartLine } from 'react-icons/fa';
import { motion } from 'framer-motion';

const features = [
  {
    icon: <FaLock className="h-12 w-12 text-primary dark:text-white" />,
    title: 'Automation at Scale',
    description: 'State-of-the-art Deep Reinforcement Learning agents to automate your workflows of incident management.',
  },
  {
    icon: <FaRobot className="h-12 w-12 text-primary dark:text-white" />,
    title: 'AI-Powered Threat Detection',
    description: `Leveraging state of the art LLM's to power threat detection engine.`,
  },
  {
    icon: <FaShieldVirus className="h-12 w-12 text-primary dark:text-white" />,
    title: 'Comprehensive Protection',
    description: 'Fine-Tuned across multiple datasets to generalize threat detection.',
  },
  {
    icon: <FaChartLine className="h-12 w-12 text-primary dark:text-white" />,
    title: 'Analytics & Reporting',
    description: 'Detailed insights and reports to help you understand your security posture.',
  },
];

const Features = () => (
  <section id="features" className="py-20 bg-gray-50 dark:bg-gray-800 dark:text-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-white">Our Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-white dark:bg-gray-700 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2, duration: 0.6 }}
          >
            <div className="mb-6">{feature.icon}</div>
            <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
            <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

export default Features;
